
import { Options, Vue } from "vue-class-component";
// OpenLayers
import "ol/ol.css";
import { defaults as interactionDefaults, Select } from "ol/interaction";
import { Fill, Stroke, Style, Text } from "ol/style";
import { fromLonLat } from "ol/proj";
import { Point } from "ol/geom";
import { OSM, Vector as VectorSource } from "ol/source";
import { Tile as TileLayer, Vector as VectorLayer } from "ol/layer";
import {
  default as MapChart,
  Options as ChartOptions,
} from "ol-ext/style/Chart";
// import { Color } from "ol/color";
import Feature, { FeatureLike } from "ol/Feature";
import Map from "ol/Map";
import View from "ol/View";
import { IState } from "src/interfaces/state.interface";
import { IStateExpropriationsByYear } from "src/interfaces/state-expropriations-by-year.interface";
import { IExpropriation } from "src/interfaces/expropriation.interface";

@Options({
  props: {
    states: Array,
  },
})
export default class FactsMap extends Vue {
  states!: IState[];

  // Map stuff
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  styleCache = {} as { [key: string]: any };
  mapCenter = [10.451526, 51.165691]; // Germany
  map!: Map;

  vw = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );
  vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );

  mounted(): void {
    this.initMap();
  }

  protected getFeatureStyle(feature: FeatureLike, selected?: boolean): Style[] {
    // Local style storage
    const k =
      Math.random() +
      "-" +
      Math.random() +
      "-" +
      (selected ? "1-" : "") +
      feature.get("data");

    let style = this.styleCache[k];
    if (!style) {
      let radius = 15;
      // area proportional to data size: s=PI*r^2
      radius = 10 * Math.sqrt(feature.get("sum") / Math.PI);
      // data has to be an array of numbers (prerequisite of the map chart library)
      const data = feature
        .get("data")
        .flatMap((expropriation: IExpropriation) => {
          const { amount } = expropriation;
          if (amount && amount > 0) {
            return amount;
          }
          return 0;
        });
      // Slightly increase radius on click
      radius *= selected ? 1.2 : 1;

      // Create chart style
      const chartOptions = {
        type: "pie",
        colors: "classic",
        rotation: 0,
        snapToPixel: true,
        offsetX: 0,
        offsetY: 0,
        animation: 1,
        max: 100,
        radius,
        stroke: new Stroke({
          color: "#fefefe",
          width: 1.5,
        }),
        data,
      } as ChartOptions;

      style = [
        new Style({
          image: new MapChart(chartOptions),
        }),
      ];

      // Show values on click
      if (selected) {
        const sum = feature.get("sum");

        let s = 0;
        // `data` is an array with all expropriation amounts (incl. `-1` and `0`)
        for (let i = 0; i < data.length; i++) {
          const amount = data[i] > -1 ? data[i] : 0;
          const a = ((2 * s + Math.ceil(amount)) / sum) * Math.PI - Math.PI / 2;

          if (amount > 0) {
            // Find the matching expropriation object by the array containing only the `amount` values
            let qualifier = "";
            switch (i) {
              case 0:
                qualifier = "laufend";
                break;
              case 1:
                qualifier = "abgeschlossen";
                break;
              case 2:
                qualifier = "neu begonnen";
                break;
              default:
                qualifier = "geplant";
                break;
            }
            // Label text per pie part
            const text = `${amount} ${qualifier}`;
            style.push(
              new Style({
                text: new Text({
                  text,
                  offsetX: Math.cos(a) * (radius + 3),
                  offsetY: Math.sin(a) * (radius + 3),
                  textAlign: a < Math.PI / 2 ? "left" : "right",
                  textBaseline: "middle",
                  fill: new Fill({ color: "#000" }),
                  scale: 1.4,
                  stroke: new Stroke({ color: "#fefefe", width: 3 }),
                }),
              })
            );
          }
          s += amount;
        }
      }
    }
    this.styleCache[k] = style;
    return style;
  }

  private initMap(): void {
    const BaseOSMLayer = new TileLayer({
      source: new OSM(),
    });
    const SeaMapLayer = new TileLayer({
      source: new OSM({
        opaque: false,
        url: "https://tiles.openseamap.org/seamark/{z}/{x}/{y}.png",
      }),
    });

    this.map = new Map({
      target: "map",
      layers: [BaseOSMLayer, SeaMapLayer],
      view: new View({
        center: fromLonLat(this.mapCenter),
        zoom: this.vw <= 768 && this.vh > this.vw ? 5.6 : 6.4,
        maxZoom: 8,
      }),
      interactions: interactionDefaults({
        mouseWheelZoom: false,
        dragPan: false,
      }),
    });

    let features: Feature[] = [];
    if (this.states) {
      this.states.forEach((state: IState, i: number) => {
        let data: IExpropriation[] = [];
        let sum = 0;
        state.expropriationsByYear.forEach(
          (stateExpropriations: IStateExpropriationsByYear) => {
            if (stateExpropriations.year === 2020) {
              stateExpropriations.expropriations.forEach(
                (expropriation: IExpropriation) => {
                  if (expropriation) {
                    data.push(expropriation);
                    const { amount } = expropriation;
                    if (amount && amount > -1) {
                      sum += amount;
                    }
                  }
                }
              );
            }
          }
        );

        features[i] = new Feature({
          geometry: new Point(fromLonLat(state.coordinates)),
          data,
          sum,
        });
      });

      const vector = new VectorLayer({
        source: new VectorSource({ features }),
        style: (feature: FeatureLike) => this.getFeatureStyle(feature),
      });
      this.map.addLayer(vector);

      // Control Select
      var select = new Select({
        style: (feature: FeatureLike) => this.getFeatureStyle(feature, true),
      });
      this.map.addInteraction(select);
    }
  }
}
